.container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.left-section,
.right-section {
  display: flex;
  flex-direction: column;
}

.left-section {
  width: 60%;
}

.right-section {
  width: 35%;
}

.video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (height/width * 100) */
  position: relative;
  background-color: black;
}

.video iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.description {
  margin-top: 15px;
}

.read-more {
  margin-top: 10px;
  color: blue;
  cursor: pointer;
}

.thumbnail {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.thumbnail img {
  width: 200px;
  height: 118px;
  border-radius: 10px;
}

.thumbnail-description {
  display: flex;
  flex-direction: column;
}

.youtube-channel-info {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.youtube-profile-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.youtube-channel-details {
  display: flex;
  flex-direction: column;
}

.youtube-channel-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.youtube-subscribe-button {
  padding: 5px 10px;
  background-color: #4ba889;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.corner-wrapper {
  display: block;
  overflow: hidden;
  border-radius: 10px;
  transform: translateZ(0px);
  border: 3px solid #eee;
}

/* Existing CSS remains unchanged */

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .left-section,
  .right-section {
    width: 100%;
  }

  .right-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .thumbnail {
    width: calc(33.33% - 10px); /* Three thumbnails per row */
    flex-direction: column;
    margin-bottom: 20px;
  }

  .thumbnail img {
    width: 100%;
    height: auto;
  }

  .thumbnail-description {
    margin-top: 10px;
  }

  .thumbnail-description h4 {
    font-size: 14px;
  }

  .thumbnail-description p {
    font-size: 12px;
  }
}

/* Other existing media queries remain unchanged */
