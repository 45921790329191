.all-services-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 100%;
  color: #fff;
  overflow: hidden;
}

.all-services-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/our-services2.webp");
  background-size: cover;
  background-position: center center;
  filter: brightness(0.3);
  z-index: 0;
}

.all-services-banner {
  position: relative;
  z-index: 1;
  text-align: center;
}

.all-services-text {
  font-weight: 800;
  font-size: 3em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
}

.all_services-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  padding: 20px;
  margin: 20px 0;
}

.all_services_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px;
  padding: 30px;
}

.all_services-card {
  width: 200px;
  height: 170px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #4ab899;
  cursor: pointer;
  transition: transform 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.all_services-card:hover {
  transform: scale(1.1);
}

.all_services-card-img-top {
  height: 75px; /* Adjust height as needed */
  width: 60px; /* Fixed width for image */
  object-fit: cover;
  margin-top: 20px; /* Adjust margin as needed */
}

.all_services-card-body {
  height: 150px; /* Fixed height for title container */
  display: flex;
  align-items: center;
  justify-content: center;
}

.all_services-card-body h6 {
  font-size: 16px; /* Adjust as needed */
  margin: 0;
  text-align: center; /* Center align the title */
}

/* Responsive card sizing */
@media (min-width: 1200px) {
  /* For screens wider than 1200px (large desktops) */
  .all_services-card {
    width: calc(25% - 40px); /* 4 cards with 50px gap */
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  /* For screens between 768px and 1199px (tablets) */
  .all_services-card {
    width: calc(33.33% - 40px); /* 3 cards with 40px gap */
  }
}

@media (max-width: 767px) {
  /* For screens narrower than 768px (phones) */
  .all_services-cards-container {
    justify-content: center; /* Center align cards */
    gap: 20px; /* Adjust the gap between cards */
    padding: 10px;
  }

  .all_services-card {
    width: calc(50% - 20px); /* Two cards with 20px gap */
    max-width: 200px;
  }

  .all_services-card-body h6 {
    font-size: 12px;
  }
}
