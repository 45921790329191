.about-banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 100%;
  color: #fff;
  overflow: hidden;
}

.about-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/about-banner2.png");
  background-size: cover;
  /* background-position: center center; */
  filter: brightness(0.9);
  z-index: 0;
}

.about-us-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.about-us-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1200px;
}

.text-content {
  flex: 0 0 45%; /* Left side width */
  padding-right: 20px;
}

.image-content {
  flex: 0 0 55%; /* Right side width */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.background-image img {
  display: block;
  width: 100%;
  height: auto;
}

.foreground-images {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  z-index: 2;
}

.foreground-images img {
  max-width: calc(60% - 10px);
}

img.plus-vector {
  height: 190;
  margin-right: 1.7em;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .about-banner {
    height: 180px; /* Adjust height as needed */
  }

  .about-banner::before {
    width: 100%;
    background-size: cover;
    /* Ensure background covers the entire banner */
  }

  .about-us-content {
    flex-direction: column;
    align-items: center;
  }

  .text-content,
  .image-content {
    width: 100%;
    text-align: center;
  }

  .foreground-images,
  .background-image {
    /* flex-direction: column;
    align-items: center;
    margin-top: 20px; */
    display: none;
  }
}
