.contact-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 100%;
  color: #fff;
  overflow: hidden;
}

.contact-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/contact-banner.png");
  background-size: cover;
  background-position: center center;
  filter: brightness(0.7);
  z-index: 0;
}

.contact-banner {
  position: relative;
  z-index: 1;
  text-align: center;
}

.contact-text {
  font-weight: 800;
  font-size: 3em; /* Adjust the size as needed */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add text shadow */
  padding: 10px 20px;
}

.contact-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-top: 1px solid #ddd; /* Add top border for the contact container */
  background-color: #f8f8f8; /* Optional: Set a background color for the contact */
}

.contact-column {
  flex: 1;
  border: 1px solid #4ab899; /* Apply border to each column */
  border-radius: 5px; /* Optional: Add rounded corners */
  padding: 20px;
  margin: 10px; /* Add some margin between the columns */
  background-color: #fff; /* Optional: Set a background color for the columns */
  text-align: center;
}

.contact-column p,
.contact-column a {
  margin: 10px 0; /* Add some space between paragraphs and links */
}

.contact-column a {
  color: #000; /* Set link color */
  text-decoration: none; /* Remove underline from links */
}

.contact-column a:hover {
  text-decoration: underline; /* Add underline on hover */
}

.social-icons-contact {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.social-icons-contact > * {
  margin-right: 10px; /* Space between icons */
}

.contact-section {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.contact-map-form-container {
  display: flex;
  width: 100%;
  max-width: 1200px;
}

.map-container {
  flex: 0 1 60%;
  padding-right: 20px;
}

.contact-enquiry-container {
  flex: 0 1 40%;
  display: flex;
  justify-content: center;
}

.contact-enquiry-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contact-form-fields {
  display: flex;
  flex-direction: column;
  background: #4ab899;
  padding: 15px;
}

.contact-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contact-input-wrapper i {
  position: absolute;
  left: 10px;
  color: #888;
}

.contact-input-wrapper input,
.contact-input-wrapper select,
.contact-input-wrapper textarea {
  width: 100%;
  padding: 10px 10px 10px 35px; /* Padding to make space for the icon */
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-input-wrapper textarea {
  resize: none;
  height: 200px;
}

.submit-button {
  margin-top: 10px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  width: 180px;
}

.submit-button:hover {
  background-color: #0056b3;
}

@media screen and (max-width: 900px) {
  .contact-map-form-container {
    flex-direction: column;
  }

  .map-container,
  .contact-enquiry-container {
    width: 100%;
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }
  .contact-column {
    width: 100%;
    margin: 10px 0; /* Adjust margin for column layout */
  }
}
