@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  font-family: "Inter", sans-serif;
}

html body {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}
