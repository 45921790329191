.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 100%;
  color: #fff;
  overflow: hidden;
}

.wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/banner1.jpg");
  background-size: cover;
  background-position: center center;
  filter: brightness(0.6);
  z-index: 0;
  opacity: 0.8;
}

.banner-content {
  position: relative;
  z-index: 1;
  text-align: center;
}

.wrapper h1,
.wrapper p {
  line-height: 1.2;
  margin-bottom: 10px;
  font-weight: 700;
  font-weight: 800;
  font-size: 2em; /* Adjust the size as needed */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add text shadow */
  padding: 10px 20px;
}

.banner-img {
  width: 100%;
  height: 70vh;
}

.wrapper h1,
.wrapper p {
  line-height: 1.2;
  margin-bottom: 10px;
  /* color: #4ba889; */
}

.wrapper .btn {
  text-decoration: none;
  color: #fff;
  font-weight: 700;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 40px auto 0 auto;
  font-size: 25px;
  background: #4ba889;
  padding: 6px 12px;
  border-radius: 10px;
  transition: all 200ms ease;
}

.btn:hover {
  transform: translateY(-0.25em);
  border-color: #4ba889;
}

.banner-content {
  text-align: center;
}

@media screen and (min-width: 900px) {
  .wrapper h1 {
    font-size: 40px;
  }

  .wrapper p {
    font-size: 20px;
  }
}

.content-area {
  padding: 20px;
}

/* Three Button Section */

.three-button-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  flex-wrap: wrap; /* Allows buttons to wrap on smaller screens */
}

.three-button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 100%;
  width: 50%; /* Width for larger screens */
  padding: 15px 0;
}

.three-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%; /* Adjust the width as needed */
  margin: 10px;
  background: #fff;
  border: 2px solid #4ba889;
  border-radius: 30px;
  transition: all 200ms ease;
}

.three-btn-text {
  margin: 0;
  padding: 1px 10px;
  color: #4ba889;
  font-weight: 800;
  text-align: center;
  font-size: 18px;
}

.three-btn-text-desc {
  color: #4ba889;
  margin: 0;
  text-align: center;
  font-size: 14px;
}

.green-btn {
  background-color: #4ba889;
}

.green-text {
  color: #fff;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .three-button {
    width: 100%; /* Full width on tablet */
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center buttons */
  }

  .three-btn {
    width: 80%; /* Wider buttons on tablet */
    margin: 10px 0; /* Vertical margin */
  }

  .three-btn-text {
    font-size: 16px; /* Adjust text size */
  }

  .three-btn-text-desc {
    font-size: 12px; /* Adjust description text size */
  }
}

@media (max-width: 480px) {
  .three-btn {
    width: 90%; /* Almost full width on mobile */
  }

  .three-btn-text {
    font-size: 14px; /* Smaller text size */
  }

  .three-btn-text-desc {
    font-size: 12px; /* Smaller description text size */
  }
}

/* About Us */

.about-us-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* padding: 30px 0px; */
  gap: 10px;
  width: 100%;
  /* height: 100vh; */
  /* border-top: 5px solid #95c4b4; */
}

.about-us-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%; /* Adjust this to add space on the sides */
  max-width: 1200px; /* Add a max-width for better responsiveness */
}

.text-content {
  position: relative; /* Ensure positioning context for pseudo-elements */
  /* background-image: url("../../assets/Ragini-Logo-Opaque.png");
  background-size: 100% 100%;
  background-position: center; */
  padding: 20px;
}

/* .image-content {
  flex: 1;
  display: flex;
  justify-content: center;
}

.image-content img {
  max-width: 100%;
  height: auto;
  width: 60%;
} */

.image-content {
  position: relative; /* Establish positioning context for absolute positioning */
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: flex-start; /* Align items to the top */
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.background-image img {
  display: block;
  width: 100%;
  height: auto;
}

.foreground-images {
  display: flex;
  justify-content: space-between; /* Distribute items evenly */
  margin-top: 50px; /* Adjust the space on top */
  z-index: 2; /* Place the foreground images above the background */
}

.foreground-images img {
  max-width: calc(60% - 10px); /* Adjust width as needed */
  /* height: auto; */
}

.foreground-images img:first-child {
  margin-right: 10px;
  margin-left: 15px;
}

img.plus-vector {
  height: 200px;
  margin-right: 10px;
}

/* Service & Enquiry */

.service-enquiry-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 20px;
  min-height: 70vh;
}

.service-left-section {
  flex: 0 1 30%;
}

.service-right-section {
  flex: 0 1 65%;
}

.service {
  width: 100%;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.card {
  color: #fff;
  border: 1px solid #4ba889;
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.5s;
}

.card:hover {
  transform: scale(1.05);
}

.card-img-top {
  width: 150px;
  height: 150px;
  object-fit: contain;
  padding: 10px;
}

.card-body {
  padding: 15px 0;
  width: 100%;
  background-color: #dbfbf3;
  color: #4ba889;
  text-align: center;
  margin: 0;
}

.card-body h6 {
  margin: 0;
  font-size: 14px;
}

@media screen and (max-width: 1200px) {
  .cards-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .service-enquiry-container {
    flex-direction: column;
  }

  .service-right-section {
    width: 100%;
    flex: 0 1 100%;
  }
}

@media screen and (max-width: 768px) {
  .cards-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .card {
    height: auto;
  }

  .card-img-top {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 480px) {
  .cards-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }

  .card {
    border-radius: 10px;
  }

  .card-img-top {
    width: 80px;
    height: 80px;
    padding: 5px;
  }

  .card-body {
    padding: 10px 0;
  }

  .card-body h6 {
    font-size: 10px;
  }
}

/* Why Choose us */

.why-choose-section {
  background-color: #e8e8e8;
  width: 100%;
  padding: 30px 54px;
  border-top: 5px solid #95c4b4;
  border-bottom: 5px solid #95c4b4;
}

h2 {
  color: #4ba889;
  font-weight: 700;
  text-align: center;
}

.why-choose-container {
  display: flex;
  justify-content: space-around;
  /* flex-wrap: wrap; */
}

.product_card {
  width: 280px;
  height: 320px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 20px;
  transition: 0.3s;
  border: 2px solid rgb(236, 236, 236);
  position: relative;
}

.product_card img {
  width: 100%;
  height: 215px;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
}

.product_info {
  padding: 10px;
  background-color: #4ba889;
}

.product_name {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
  color: #fff;
}

.product_description {
  font-size: 0.9rem;
  color: #fff;
  margin-top: 5px;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .why-choose-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-items: center;
  }

  .product_card {
    width: 90%;
    margin: 10px 0;
  }

  h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .why-choose-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    justify-items: center;
  }

  .product_card {
    width: 100%;
  }

  h2 {
    font-size: 1.2rem;
  }

  .product_name {
    font-size: 0.9rem;
  }

  .product_description {
    font-size: 0.8rem;
  }
}

/* Videos */
.container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.left-section,
.right-section {
  display: flex;
  flex-direction: column;
}

.left-section {
  width: 60%;
}

.right-section {
  width: 35%;
}

.video {
  width: 100%;
  height: 315px; /* Adjust the height as needed */
  background-color: black; /* Placeholder for video */
}

.description {
  margin-top: 15px;
}

.read-more {
  margin-top: 10px;
  color: blue;
  cursor: pointer;
}

.thumbnail {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.thumbnail img {
  width: 200px;
  height: 118px;
  border-radius: 10px;
}

.thumbnail-description {
  display: flex;
  flex-direction: column;
  width: 100px;
}

.youtube-channel-info {
  display: flex;
  align-items: center;
  margin: 10px;
}

.youtube-profile-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.youtube-channel-details {
  display: flex;
  flex-direction: column;
}

.youtube-channel-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.youtube-subscribe-button {
  padding: 5px 10px;
  background-color: #4ba889;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.corner-wrapper {
  display: block;
  overflow: hidden;
  /* width: 300px;
    height: 150px; */
  border-radius: 10px;
  transform: translateZ(0px);
  border: 3px solid #eee;
}

.corner-wrapper-thumbnail {
  border-radius: 10px;
  transform: translateZ(0px);
  border: 3px solid #eee;
}

@media screen and (max-width: 900px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
  .left-section {
    width: 100%;
  }
  .thumbnail-description h4 {
    font-size: 16px;
  }
  .thumbnail-description p {
    font-size: 12px;
  }
}

@media screen and (max-width: 676px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
  .left-section,
  .right-section {
    width: 100%;
  }
  .left-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .thumbnail {
    width: calc(50% - 10px); /* Adjust width to fit two thumbnails with gap */
    margin-bottom: 20px;
  }
}

/* Testimonials */

.testimonial-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  background-color: #4ba889;
}

.testimonial-left {
  flex: 0 0 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ratings-icon {
  font-size: 24px;
  color: #ffc107;
}

img.colons {
  width: 125px;
  margin-bottom: 115px;
}

.testimonial-right {
  flex: 0 0 75%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.testimonial-cards {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.testimonial-card {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 45%;
}

.testimonial-text {
  font-size: 16px;
  margin-bottom: 20px;
}

.testimonial-author {
  display: flex;
  align-items: center;
}

.testimonial-author img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.testimonial-author h5 {
  margin: 0;
  font-size: 16px;
}

.testimonial-author p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

@keyframes slideRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.slider-icon {
  font-size: 32px;
  color: #fff;
  cursor: pointer;
  animation: slideRight 1s infinite;
}

.slider-icon:hover {
  animation-play-state: paused;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .testimonial-section {
    flex-direction: column; /* Stack the left and right sections */
    padding: 30px; /* Reduce padding for smaller screens */
  }

  .testimonial-left {
    flex: 0 0 100%; /* Take full width on smaller screens */
    margin-bottom: 20px; /* Add space below */
  }

  .testimonial-right {
    flex: 0 0 100%; /* Take full width on smaller screens */
  }

  img.colons {
    width: 50px; /* Adjust size for smaller screens */
    margin-bottom: 60px;
  }

  .testimonial-card {
    max-width: 90%;
    margin: 10px auto;
    width: 280px;
  }

  .testimonial-cards {
    flex-direction: column; /* Stack cards vertically */
    align-items: center; /* Center cards */
    width: 100%; /* Full width */
  }
}

@media (max-width: 480px) {
  .ratings-icon {
    font-size: 10px; /* Adjust size */
  }

  .slider-icon {
    font-size: 24px; /* Smaller icon size */
  }

  .testimonial-text {
    font-size: 14px; /* Smaller text size */
  }

  .testimonial-author h5 {
    font-size: 14px; /* Smaller name size */
  }

  .testimonial-author p {
    font-size: 12px; /* Smaller role size */
  }
}

/* Enquiry */

.enquiry-container {
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  padding: 23px 10px;
  /* gap: 30px; */
  border: 1px solid #4ba889;
  border-radius: 10px;
  /* position: absolute; */
  /* width: 100%; */
  /* height: 479.02px; */
  /* left: calc(50% - 1338px / 2); */
  background: #4ba889;
  /* top: 3421px; */
}

.enquiry-form {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.left-fields {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.input-wrapper {
  position: relative;
  margin-bottom: 7px;
}

.input-wrapper i {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #999;
}

.input-wrapper input,
.input-wrapper select {
  width: 100%;
  padding: 10px 10px 10px 30px;
  font-size: 16px;
  box-sizing: border-box;
  border: 1px solid #4ba889;
  border-radius: 6px;
  height: 38px;
}

.input-wrapper select {
  height: 40px;
}

.right-field {
  width: 58%;
  display: flex;
  align-items: flex-start;
}

.right-field textarea {
  width: 100%;
  height: calc(4 * 40px + 3 * 20px); /* 4 input heights + 3 gaps */
  padding: 10px;
  font-size: 16px;
  resize: none;
  border: 1px solid #4ba889;
  border-radius: 8px;
}

.enquiry-image {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.enquiry-submit {
  display: block;
  margin: 20px auto; /* Center horizontally and add some space above */
  padding: 10px 20px;
  width: 200px;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.enquiry-btn-container {
  text-align: center; /* Center-align child elements */
}

@media (max-width: 480px) {
  .enquiry-image {
    display: none;
  }
}

/* Whatsapp Floating icon */

.float {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 150px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  transition: transform 0.5s;
}

.float:hover {
  transform: scale(1.2);
}

.my-float {
  margin-top: 10px;
}

/* Animation */

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in.visible {
  opacity: 1;
}
