.admin-dashboard {
  display: flex;
  margin-top: 20px;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.sidebar {
  width: 250px;
  background-color: #2196f3;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 40px;
}

.menu-item {
  background: none;
  border: none;
  color: white;
  padding: 10px;
  text-align: left;
  font-size: 16px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.menu-item .icon {
  margin-right: 10px;
}

.menu-item.active {
  background-color: rgba(255, 255, 255, 0.2);
}

.upgrade-btn {
  margin-top: auto;
  background-color: white;
  color: #2196f3;
  border: none;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
}

.content {
  flex-grow: 1;
  background-color: #f4f4f4;
  padding: 20px;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
}

.search-bar input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;
}

.search-bar button {
  background-color: white;
  border: 1px solid #ddd;
  border-left: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
}

.user-profile {
  display: flex;
  align-items: center;
}

.user-profile > * {
  margin-left: 15px;
  cursor: pointer;
}

.avatar {
  width: 40px;
  height: 40px;
  background-color: #ddd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data-section {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f8f8f8;
  font-weight: bold;
}
/* Add this to your existing ad.css file */

.user-profile {
  position: relative;
}

.profile-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.profile-dropdown button {
  width: 100%;
  padding: 5px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.profile-info {
  margin-bottom: 10px;
  padding: 0 5px;
}

.profile-info p {
  margin: 5px 0;
}

.profile-dropdown button:hover {
  background-color: #e0e0e0;
}

.error-message {
  color: red;
  font-weight: bold;
}

.export-button {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 16px;
}

.export-button:hover {
  background-color: #45a049;
}