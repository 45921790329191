.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 15px 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.3s ease-out;
  z-index: 1000;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.notification p {
  margin: 0;
  padding-right: 15px;
}

.notification button {
  background: none;
  border: none;
  color: inherit;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
}

.notification.info {
  background-color: #2196f3;
  color: white;
}

.notification.success {
  background-color: #4caf50;
  color: white;
}

.notification.warning {
  background-color: #ffc107;
  color: black;
}

.notification.error {
  background-color: #f44336;
  color: white;
}
